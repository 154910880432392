import React, { Suspense } from "react";
import { Outlet } from "react-router-dom";
import "./Scss/main.css";
import { ThemeProvider, Typography, Box } from "@mui/material";
import { createCustomTheme } from "./Themes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function App() {
  const theme = createCustomTheme();
  return (
    <div>
      <Suspense
        fallback={
          <Box className="displayCenterLogo">
            <Typography variant="h6" className="logoText">
              MEV
            </Typography>
          </Box>
        }
      >
        <ThemeProvider theme={theme}>
          <ToastContainer />
          <Outlet />
        </ThemeProvider>
      </Suspense>
    </div>
  );
}

export default App;
