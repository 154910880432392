import React from "react";
import { Outlet } from "react-router-dom";
import Topbar from "../Topbar";

const LoginLayout = () => {
  return (
    <div>
      <Topbar />
      <main>
        <Outlet />
      </main>
    </div>
  );
};

export default LoginLayout;
