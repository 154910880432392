import React, { lazy } from "react";
import LoginLayout from "./Layouts/LoginLayout/LoginLayout";

// const HomeScreen = lazy(() => import("./Views/Pages/wf/HomeScreen.jsx"));
const TablePage = lazy(() => import("./Views/Pages/wf/TablePage"));

const Login = lazy(() => import("./Views/Pages/auth/Login"));
const ForgetPassword = lazy(() => import("./Views/Pages/auth/ForgetPassword"));
const ResetPassword = lazy(() => import("./Views/Pages/auth/ResetPassword"));
const VerifyOtp = lazy(() => import("./Views/Pages/auth/VerifyOtp"));

const routes = [
  {
    path: "/",
    element: <LoginLayout />,
    children: [
      { path: "", element: <Login /> },
      { path: "login", element: <Login /> },
      { path: "forgot-password", element: <ForgetPassword /> },
      { path: "verify-otp", element: <VerifyOtp /> },
      { path: "reset-password", element: <ResetPassword /> },
      { path: "home", element: <TablePage /> },
    ],
  },
];

export default routes;
