import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { colors } from '@mui/material';

const baseOptions = {
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: 'transparent',
        },
        head: {
          background: '#F4F7FF',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {},
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: { color: '#222' },
        colorSecondary: {
          '&.Mui-focused': {
            color: '#222',
          },
        },
      },
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          color: '#000000',
          fontSize: '22px',
          fontWeight: '600',
          lineHeight: '33px',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          background: 'rgba(0, 0, 0, 0.05)',
          borderRadius: '7px',
          height: '48px',
        },
        notchedOutline: {
          borderColor: 'rgba(0, 0, 0, 0.08)',
        },
        colorSecondary: {
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            color: '#222',
            borderColor: '#222',
          },
          '&.Mui-focused': {
            color: '#222',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        outlined: {
          padding: '20px',
          width: '100%',
        },
        elevation1: {
          background: '#fff',
          borderRadius: '10px',
          padding: '26px 20px',
          boxShadow: 'none',
        },
        elevation2: {
          background:
            'linear-gradient(180deg, rgba(0, 0, 0, 0.03) 0%, rgba(0, 0, 0, 0) 68.15%)',
          border: '2px solid rgba(0, 0, 0, 0.15)',
          borderRadius: '10px',
          padding: '0px',
          boxShadow: 'none',
          marginBottom:"30px"
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        root: {
          zIndex: 99999,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: { paddingLeft: '20px' },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          alignItems: 'self-start',
        },
        gutters: {
          paddingLeft: 0,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: '4px',
          fontSize: '12px',
        },
        colorSecondary: {
          '&.Mui-checked': { color: '#000' },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          paddingBottom: '0',
        },
      },
    },
    MuiListItemSecondaryAction: {
      styleOverrides: {
        root: {
          right: 0,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paperScrollPaper: {
          Width: 450,
          maxWidth: '100%',
        },
        paper: {
          overflowY: 'unset',
        },
        paperWidthSm: {
          maxWidth: '900px !important',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontSize: 14,
          color: '#222',
          height: '0.1876em',
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: { backgroundColor: 'rgba(0, 0, 0, 0.75)' },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        option: {
          fontFamily: 'Poppins',
          fontSize: '12px',
          fontWeight: '400',
          lineHeight: '18px',
          letterSpacing: '0px',
          textAlign: 'left',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        containedSecondary: {
          color: '#262626',
          height: '38px',
          padding: '10px 39px',
          fontSize: '14px',
          border: '1px solid #E1E1E1',
          background: '#F2F2F2',
          fontWeight: '500',
          lineHeight: '21px',
          borderRadius: '4px',
          backgroundColor: '#F2F2F2',
          '&:hover': {
            background: '#646464',
            border: '1px solid #646464',
            boxShadow: 'none',
            color: '#fff',
            backgroundColor: '#646464',
          },
        },
        containedPrimary: {
          color: '#fff',
          height: '38px',
          padding: '10px 39px',
          fontSize: '14px',
          background: '#EC1F24',
          border: '1px solid #EC1F24',
          fontWeight: '500',
          lineHeight: '21px',
          borderRadius: '4px',
          backgroundColor: '#EC1F24',
          '&:hover': {
            color: '#262626',
            border: '1px solid #E1E1E1',
            backgroundColor: '#F2F2F2',
          },
        },
        contained: {
          borderRadius: '50px',
          fontWeight: 600,
          padding: '5px 19px',
          boxShadow: 'none',
        },
        outlinedPrimary: {
          color: '#EC1F24',
          border: '1px solid #EC1F24',
          padding: '5px 25px',
          fontWeight: '500',
          borderRadius: '50px',
          fontSize: '13px',
          '&:hover': {
            backgroundColor: '#EC1F24',
            border: '1px solid #EC1F24',
            color: '#fff',
          },
        },
        outlinedSizeSmall: {
          padding: '6px 23px',
          fontSize: '16px',
          lineHeight: '24px',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paperAnchorDockedLeft: {
          borderRight: '0',
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: { top: '47px' },
      },
    },
    MuiTypography: {
      styleOverrides: {
        subtitle1: {
          color: '#000',
          fontSize: '14px',
          fontWeight: 500,
          lineHeight: '16px',
        },
      },
    },
  },
};

const themesOptions = {
  typography: {
    fontWeight: 400,
    fontFamily: 'Poppins, sans-serif',
  },
  palette: {
    mode: 'light',
    action: {
      primary: '#20509e',
    },
    background: {
      default: '#FBFBFD',
      paper: colors.common.white,
    },
    primary: {
      main: '#898989',
      dark: '#de0d0d',
      light: '#de0d0d',
    },
    secondary: {
      main: '#fff',
    },
    warning: {
      main: '#ffae33',
      dark: '#ffae33',
      light: '#fff1dc',
    },
    success: {
      main: '#54e18c',
      dark: '#54e18c',
      light: '#e2faec',
    },
    error: {
      main: '#ff7d68',
      dark: '#ff7d68',
      light: '#ffe9e6',
    },
    text: {
      primary: '#52565c',
      secondary: '#999999',
    },
    common: {
      black: '#222222',
    },
  },
};

export const createCustomTheme = (config = {}) => {
  let theme = createTheme({ ...baseOptions, ...themesOptions });

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
