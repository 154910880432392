import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import App from "./App";
import routes from "./routes";

// import * as serviceWorker from "./serviceWorker";

ReactDOM.render(
  <Router>
    <Routes>
      <Route path="/" element={<App />}>
        {routes.map((route, index) => (
          <Route key={index} path={route.path} element={route.element}>
            {route.children &&
              route.children.map((childRoute, childIndex) => (
                <Route
                  key={childIndex}
                  path={childRoute.path}
                  element={childRoute.element}
                />
              ))}
          </Route>
        ))}
      </Route>
    </Routes>
  </Router>,
  document.getElementById("root")
);
