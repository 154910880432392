import * as React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import { Button, Typography } from "@mui/material";
import "../Views/Pages/wf/editScr.css";

function Topbar(props) {
  const navigate = useNavigate();

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        component="nav"
        sx={{
          /* backgroundColor: "transparent" */ backgroundColor: "#f6f6f6",
          boxShadow: "none",
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Box
              // onClick={() => navigate("/")}
              sx={{ cursor: "pointer" }}
            >
              <Typography variant="h6" className="logoText">
                MEV
              </Typography>
            </Box>
            {window.sessionStorage.getItem("token") && (
              <Button
                variant="contained"
                size="large"
                color="primary"
                sx={{ ml: 2 }}
                type="submit"
                onClick={() => {
                  window.sessionStorage.removeItem("token");
                  navigate("/");
                }}
              >
                Logout
              </Button>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="main" sx={{ p: 3 }}>
        <Toolbar />
      </Box>
    </Box>
  );
}

Topbar.propTypes = {
  window: PropTypes.func,
};

export default Topbar;
